import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ScoreContainer } from '..';
import { ScoreInterview } from '..';
import { get_interview_version } from '../api/api';
import { newManagerBaseUrl } from 'config';

export const ScoreProvider = () => {
  let { id } = useParams();
  const [template_version, set_template_version] = useState(undefined);
  const [error, set_error] = useState(undefined);
  const [error_msg, set_error_msg] = useState('');

  useEffect(() => {
    get_interview_version(`${id}`)
    .then(res => {

      if (res.process_id) {
        return window.location.replace(`${newManagerBaseUrl}/score/${id}`);
      }
      set_template_version(res.template_version);
    }).catch(error => {
      set_error(true);
      set_error_msg(error.toString());
    });
  }, []);

  useEffect(() => {
    document.title = `Score Interview`;
  }, []);

  if (error) {
    return (
      <div>Error with interview version. {error_msg}</div>
    );
  }

  if ([undefined, null].includes(template_version)) {
    return (
      <div>Interview not started.</div>
    );
  }

  if (template_version === 1) {
    return (
      <ScoreContainer id={id} />
    );
  }

  return (
    <ScoreInterview interview_id={id} />
  );
};
