import React, { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
} from 'react-bootstrap';

import { TemplateOverview } from 'features/TemplateOverview';
import { Template } from 'features/Template';
import { Loading } from 'features/common';
import { SaveTemplateBoard } from './SaveTemplateBoard';
import { ModalsContainer } from 'features/Modals';

import { InterviewSummaryContainer } from 'features/InterviewSummary';
import { TemplateJobs } from './TemplateJobs';

import {
  toggleStatus,
  addTemplate,
  setReadOnlyMode
} from 'features/Template/slices/TemplateSlice';

import { ResetOutline } from 'features/TemplateOutline';
import { resetEvaluation } from 'features/QutterEvaluation';
import { ResetParameters } from 'features/Parameters';
import { ResetInterviewSummary } from 'features/InterviewSummary/slices/InterviewSummarySlice';
import { newManagerBaseUrl } from 'config';

export const TemplatesContainer = ({
  readOnly=false
}) => {
  const dispatch = useDispatch();

  let { template_id } = useParams();
  const [tab_key, set_tab_key] = useState('interview');
  const [loading, setLoading] = useState(true);
  let test_job = null;


  useEffect(() => {
    dispatch(setReadOnlyMode({ mode: readOnly }));
    // Reset all stores
    dispatch(ResetOutline({ languages: null }));
    dispatch(resetEvaluation({ evaluation: null }));
    dispatch(ResetParameters({ parameters: null }));
    dispatch(ResetInterviewSummary({ template_id, interview_summary: null }));

    axios.get(
      `${process.env.REACT_APP_URL}/templates/v1/template/${template_id}`
    ).then(async res => {
      const template = res.data.template;

      if (template.process_id && template.template_reference_id) {
        return window.location.replace(`${newManagerBaseUrl}/templates/${template_id}`);
      }

      dispatch(ResetOutline({ languages: template.language_settings.list }));
      dispatch(resetEvaluation({ evaluation: template.evaluation }));
      dispatch(ResetParameters({ parameters: template.parameters }));
      dispatch(ResetInterviewSummary({ template_id, interview_summary: template.interview_summary }));

      dispatch(addTemplate({ id: template_id, template: template }));

      setLoading(false);
      dispatch(toggleStatus({ id: template_id, status: 'idle' }));
    })
    .catch(error => {
      console.log('Error', error);
    });
  }, [readOnly]);

  return (
    <>
    <ModalsContainer/>

    {/* {config.status === 'loading' ? ( */}
    {template_id === 'loading' ? (
      <Loading />
    ):(
    <Container
      style={{ 
        maxHeight: (readOnly ? '100vh': '85vh'),
        overflowY: 'auto',
        maxWidth: 'none'
      }}
      className=''
    >
      <Row
        style={{ 
          maxHeight: (readOnly ? '100vh': '85vh'),
          overflowY: 'auto'
        }}
      >

        <Col 
          xs="12" md="3" lg="3"
          style={{
            maxHeight: (readOnly ? '100vh': '85vh'),
            overflowY: 'auto'
          }}
          className='pb-5'
        >
          <TemplateOverview 
            id={template_id} 
            test_job={test_job} 
          />
        </Col>

        <Col 
          id='search-qutter-container'
          style={{
            maxHeight: (readOnly ? '100vh': '85vh'),
            overflowY: 'auto'
          }}
          className='pb-5'
        >
          <Tabs
            id="templates-tabs"
            activeKey={tab_key}
            onSelect={k => set_tab_key(k)}
            className="mb-3"
            style={{ borderBottom: 'unset' }}
          >
            <Tab eventKey="interview" title="Interview">
              {!loading && tab_key && tab_key === 'interview' ? (
                <Template
                  id={template_id}
                />
              ) : (
                <span>Nothing to show</span>
              )}
            </Tab>

            <Tab eventKey="summary" title="Summary">
              {!loading && tab_key && tab_key === 'summary' ? (
                <InterviewSummaryContainer template_id={template_id} />
              ) : (
                <span>Nothing to show</span>
              )}
            </Tab>

            {readOnly ? (null):(
            <Tab eventKey="template-jobs" title="Jobs using this template">
              {!loading && tab_key && tab_key === 'template-jobs' ? (
                <TemplateJobs templateId={template_id} />
              ) : (
                <span>Nothing to show</span>
              )}
            </Tab>
            )}

          </Tabs>
        </Col>
      </Row>
      {readOnly ? (null):(
      <Row style={{ position: 'absolute', right: 0, bottom: 0 }}>
        <Col>
          {!loading && (
            <SaveTemplateBoard
              id={template_id}
              tab_key={tab_key}
              set_tab_key={set_tab_key}
            />
          )}
        </Col>
      </Row>
      )}
    </Container>
    )}
    </>
  );
};